const TOTAL_STARS = 5;
const STAR_POSITION = 1;
const MIN_WIDTH = 20;

export default {
	name: 'StarRating',
	props: ['rating'],
	data() {
		return {
			totalStars: TOTAL_STARS,
			currentRatingWidth: 0,
			ratingStar: 0,
		};
	},
	watch: {
		rating(newValue) {
			this.onRate(this.findIndexFromStar(newValue), { isManualRating: false });
		}
	},
	methods: {
		onRate(index, { isManualRating }) {
			this.ratingStar = index + STAR_POSITION;
			this.currentRatingWidth = (index + STAR_POSITION) * MIN_WIDTH;
		},
		findIndexFromStar(ratingStar) {
			return ratingStar - STAR_POSITION;
		},
	},
	mounted() {
		console.log({ 'current rating': this.rating });
		this.onRate(this.findIndexFromStar(this.rating), { isManualRating: false });
	},
};
