<template>
	<div>
		<div class="screen-title">案件編集</div>
		<b-container fluid>
			<validation-observer class="w-100" v-slot="{ handleSubmit }" ref="formValidator">
				<b-form class="w-100" role="form" @submit.prevent="handleSubmit(onEdit)">
					<b-row class="mx-0">
						<b-col class="mx-0 px-0">
							<b-row class="mx-0 d-flex row-gap-20">
								<card class="my-0 w-100">
									<div class="card-title">基本情報</div>
									<b-row class="custom__mx-3 pb-27 col-gap-30">
										<div class="col-md col-xs-12 px-0 res_margin_bottom">
											<base-input
												clearable
												alternative
												placeholder="案件名"
												name="案件名"
												:rules="{ required: true }"
												v-model="model.projectName"
												label="案件名"
												:inputRequired="true"
												:labelClasses="'custom-form-input-label'"
												:inputClasses="'custom-input-class'"
											>
											</base-input>
										</div>
										<b-col class="px-0">
											<base-input
												label="クライアント"
												name="クライアント"
												:rules="{
													required: true,
													validate: [
														[model.clientInfo],
														'クライアントは必須です',
														validateClientInfo,
													],
												}"
												note="※ 新規のクライアントは、左メニュー「クライアント登録」を先に行ってください。"
												:inputRequired="true"
												:labelClasses="'custom-form-input-label'"
												:inputClasses="'custom-input-class'"
											>
												<el-select
													placeholder="選択なし"
													class="mb-0"
													v-model="model.clientInfo"
													clearable
												>
													<el-option
														v-for="client in filteredClientOptions"
														:key="client.value"
														:label="client.label"
														:value="client"
													>
													</el-option>
												</el-select> </base-input
										></b-col>
									</b-row>
									<b-row class="custom__mx-3 custom__row-gap-40 pb-27 col-gap-30">
										<b-col class="px-0">
											<b-row class="mx-0 d-flex flex-column">
												<b-row class="mx-0 mt-2 col-gap-30">
													<div class="col-md col-xs-12 px-0 pb-27 res_margin_bottom">
														<b-row class="mx-0">
															<div class="elm-title">通知ツール</div>
														</b-row>
														<b-row class="mx-0 mt-2">
															<b-col class="d-flex col-gap-30">
																<base-radio
																	:name="0"
																	:radioName="'メール'"
																	v-model="model.notifyMethod"
																></base-radio>
																<!-- <base-radio
																	:name="1"
																	:radioName="'LINE'"
																	v-model="model.notifyMethod"
																></base-radio> -->
																<base-radio
																	:name="2"
																	:radioName="'両方'"
																	v-model="model.notifyMethod"
																></base-radio>
																<base-radio
																	:name="3"
																	:radioName="'通知なし'"
																	v-model="model.notifyMethod"
																></base-radio>
															</b-col>
														</b-row>
														<b-row class="note mx-0 mt-2"
															>クライアントが、CoMoDeからのお知らせを受け取る方法を選べます。</b-row
														>
													</div>
													<!-- <div class="col-md col-xs-12 px-0 res_margin_bottom">
														<b-row class="mx-0">
															<div class="elm-title">源泉徴収税</div>
														</b-row>
														<b-form-checkbox-group
															id="checkbox-group-1"
															:options="withHoldingTaxOption"
															v-model="model.withHoldingTax"
															name="flavour-1"
															class="mt-2"
															@change="onCheckHoldingTax"
														></b-form-checkbox-group>
													</div> -->
												</b-row>
											</b-row>
										</b-col>
									</b-row>
									<b-row class="custom__mx-3 pb-27 col-gap-30">
										<b-col class="px-0">
											<b-row class="mx-0 d-flex flex-column">
												<b-row class="mx-0 mt-2 col-gap-30">
													<div
														class="col-md col-xs-12 px-0 pb-27 d-flex align-items-center col-gap-10 res_margin_bottom"
													>
														<base-input
															clearable
															alternative
															placeholder="時間単価"
															name="時間単価"
															label="時間単価"
															:rules="{ required: true }"
															v-model="model.productionCosts"
															:inputRequired="true"
															:labelClasses="'custom-form-input-label'"
															:inputClasses="'custom-input-class'"
															:note="'見積書作成時に、時間単価× 稼働時間で自動計算されます。'"
															:unit="'円'"
															:noteError="errorNoteMsg"
															@blur="onBlurHourlyRate"
														>
														</base-input>
													</div>
												</b-row>
											</b-row>
										</b-col>
										<b-col class="custom__showHide"></b-col>
									</b-row>
								</card>
								<card class="my-0 w-100">
									<div class="card-title">備考</div>
									<textarea
										class="form-control"
										type="text"
										v-model="model.projectRemark"
										style="resize: none"
										rows="5"
									></textarea>
								</card>
							</b-row>
						</b-col>
					</b-row>
					<b-row class="w-100 mx-0 mt-3">
						<b-col class="px-0">
							<card class="my-0">
								<div class="card-title">クライアント用URL</div>
								<b-row class="mx-0">
									<div
										class="d-flex custom__flex-direction col-gap-10 align-items-baseline"
									>
										<div class="elm-title">案件プロセス画面URL</div>
										<div class="elm-caption">
											このURLはログイン不要です。該当クライアント以外には絶対にお知らせしないようご注意ください。
										</div>
									</div>
									<!-- 0: url input, 1: previewUrl input, 2: lineVerifyUrl  -->
									<base-input
										alternative
										class="mb-3"
										v-model="model.url"
										:disabled="true"
										:inputClasses="'custom-input-class'"
										:prepend-icon="copyBtn[0]"
										:textContent="textContent[0]"
										@on-icon-click="onCopy(model.url, urlNumber.url)"
									>
									</base-input>
								</b-row>
								<!-- <b-row class="mx-0">
									<div
										class="d-flex custom__flex-direction col-gap-10 align-items-baseline"
									>
										<div class="elm-title">案件プロセス参照用URL</div>
										<div class="elm-caption">
											このURLはログイン不要です。クライアント側の担当者意外の方が、プロセス画面を閲覧できます。(操作はできません)
										</div>
									</div>

									<base-input
										alternative
										class="mb-3"
										v-model="model.previewUrl"
										:disabled="true"
										:inputClasses="'custom-input-class'"
										:prepend-icon="copyBtn[1]"
										:textContent="textContent[1]"
										@on-icon-click="onCopy(model.previewUrl, urlNumber.previewUrl)"
									>
									</base-input>
								</b-row> -->
								<!-- <b-row class="mx-0">
									<div
										class="d-flex custom__flex-direction col-gap-10 align-items-baseline"
									>
										<div class="elm-title">LINEア公式アカウント登録URL</div>
										<div class="elm-caption">
											LINE通知を選択した場合、クライアントにCoMoDe
											LINE公式アカウントを登録してもらう必要があります。
										</div>
									</div>
									<base-input
										alternative
										class="mb-3"
										v-model="lineVerifyUrl"
										:disabled="true"
										:inputClasses="'custom-input-class'"
										:prepend-icon="copyBtn[2]"
										:textContent="textContent[2]"
										@on-icon-click="onCopy(lineVerifyUrl, urlNumber.lineVerifyUrl)"
									>
									</base-input>
								</b-row> -->
							</card>
						</b-col>
					</b-row>
					<b-row class="w-100 mx-0 mt-3">
						<b-col class="px-0">
							<card class="my-0">
								<div
									class="d-flex custom__flex-direction col-gap-10 align-items-baseline"
								>
									<div class="card-title">星評価</div>
									<div class="elm-caption custom__mt-14-revert">
										案件終了後に、それぞれが評価できます。その内容が反映されます。
									</div>
								</div>
								<b-row class="mx-0 d-flex align-items-center">
									<div class="d-flex col-gap-10 align-items-baseline">
										<div class="elm-title">クリエイターの評価</div>
									</div>
									<StarRating :rating="model.creatorRating" class="ml-2 star-rating" />
								</b-row>
								<b-row class="mx-0 d-flex align-items-center">
									<div class="d-flex col-gap-10 align-items-baseline">
										<div class="elm-title">クライアントの評価</div>
									</div>
									<StarRating :rating="model.clientRating" class="ml-2 star-rating" />
								</b-row>
							</card>
						</b-col>
					</b-row>
					<b-row class="mx-0 px-0 my-4 w-100 d-flex justify-content-center col-gap-20">
						<button class="customButton customButton__submit">変更</button>
						<button @click.prevent="onDelete" class="customButton customButton__delete">
							削除
						</button>
					</b-row>
				</b-form>
			</validation-observer>
		</b-container>
		<NotiModal
			:notificationModalId="notiModalId"
			:notiContent="notiContent"
			:singleBtn="notiSingleBtn"
			:redirect="redirectLink"
			@on-click="onAccept"
			@on-decline="onDeclineChangeHoldingTax"
		/>
	</div>
</template>
<script>
import ProjectService from '@/services/project.service';
import ClientService from '@/services/client.service';
import socketClient from '../../services/socket/SOCKET';

import { mapState } from 'vuex';
import CommonConst from '@/util/const';
import clientPaginationMixin from '@/mixin/clientPaginationMixin';
import hourlyRateMixin from '@/mixin/hourlyRateMixin';

import NotiModal from '@/components/Modal/NotificationModal/NotificationModal.vue';
import { Select, Option, DatePicker } from 'element-ui';
import StarRating from '@/components/StarRating/StarRating.vue';

const ADMIN_AUTHORITY = 2;

export default {
	name: 'EditProject',
	mixins: [clientPaginationMixin, hourlyRateMixin],
	components: {
		[DatePicker.name]: DatePicker,
		[Select.name]: Select,
		[Option.name]: Option,
		NotiModal,
		StarRating,
	},
	data() {
		return {
			urlNumber: {
				url: 0,
				previewUrl: 1,
				lineVerifyUrl: 2,
			},
			notiModalId: 'project-edit-noti',
			notiContent: '',
			notiSingleBtn: false,
			redirectLink: '',
			notiMode: '',
			model: {
				projectName: '',
				clientInfo: '',
				projectStartDate: '',
				projectDeliveryDate: '',
				productionCosts: undefined,
				projectRemark: undefined,
				url: undefined,
				previewUrl: undefined,
			},
			copyBtn: ['copy-btn', 'copy-btn', 'copy-btn'],
			textContent: ['URLをコピー', 'URLをコピー', 'URLをコピー'],
			clientOptions: [],
			loginUserId: '',
			editedProjectData: {},
			projectId: '',
			projectStatus: undefined,
			beforeFreezeStatus: undefined,
			projectMenuLink: 'projectmenu',
			editSuccessMessage: '変更が完了しました',
			alertMessage: '',
			showAlert: false,
			alertType: 'info',
			notifyMethod: 0,
			lineVerifyUrl: '',
			isFrezzeProject: undefined,
			frezzeProjectOptions: [
				{
					text: '進行中の案件',
					value: false,
				},
				{
					text: '保留',
					value: true,
				},
			],
			withHoldingTaxOption: [
				{
					text: '見積書・請求書に、源泉徴収税を記載する',
					value: 1,
				},
			],
		};
	},

	watch: {
		isFrezzeProject(newVal, oldVal) {
			if (oldVal === false && newVal === true) {
				this.beforeFreezeStatus = this.projectStatus;
				this.projectStatus = CommonConst.FREEZE_STATUS;
			} else if (oldVal === true && newVal === false) {
				this.projectStatus = this.beforeFreezeStatus;
				this.beforeFreezeStatus = null;
			}
		},
	},
	computed: {
		filteredClientOptions() {
			// Check if user is admin
			if (this.userInfo.authority === ADMIN_AUTHORITY) {
				const isAdminCreateProject = this.userInfo.id === this.model.responsibleUser;
				// Check if admin create project
				if (isAdminCreateProject) {
					return this.clientOptions;
				}
				// If admin is not create project
				else {
					return this.clientOptions.filter((option) => {
						return option.value == ''
							? true
							: option.responsible_id == this.model.responsibleUser;
					});
				}
			}
			// If user is not admin
			else {
				return this.clientOptions.filter((option) => {
					return option.value == ''
						? true
						: option.responsible_id == this.model.responsibleUser;
				});
			}
		},
	},
	methods: {
		validateClientInfo(clientInfo) {
			if (clientInfo.value === '') return false;
			return true;
		},
		async editProject() {
			const editedProjectInfo = {
				client_id: this.model.clientInfo.value,
				comment: this.model.projectRemark,
				cost: this.model.productionCosts,
				delivery_cost: this.model.deliveryDuration,
				project_name: this.model.projectName,
				update_user: this.loginUserId,
				notify_method: this.model.notifyMethod,
				status: this.projectStatus,
				before_freeze_status: this.beforeFreezeStatus,
			};

			let [response, errorUpdate] = await ProjectService.update(
				this.projectId,
				editedProjectInfo
			);
			if (response) {
				this.redirectLink = this.projectMenuLink;
				this.notiSingleBtn = true;
				this.notiContent = '変更しました。';
				this.$bvModal.show(`${this.notiModalId}`);
			} else {
				this.showAlert = true;
				this.alertMessage =
					'エラーが発生したため、案件が編集できない。もう一度お願いします。';
				this.alertType = 'danger';
				console.log(errorUpdate);
			}
		},

		async onEdit() {
			this.notiMode = CommonConst.EDIT_MODE;
			this.notiContent = '変更します。\nよろしいですか？';
			this.notiSingleBtn = false;
			this.$bvModal.show(`${this.notiModalId}`);
		},

		async onDelete() {
			this.notiMode = CommonConst.DELETE_MODE;
			this.notiContent =
				this.projectStatus == CommonConst.FINISH_STATUS
					? '削除します。\nよろしいですか？'
					: '現在、この案件は進行中です\n本当に削除しますか？';
			this.notiSingleBtn = false;
			this.$bvModal.show(`${this.notiModalId}`);
		},

		async deleteProject() {
			const deleteItem = {
				delete_flag: 1,
			};
			let [response, error] = await ProjectService.update(this.projectId, deleteItem);
			if (response) {
				this.$router.push(this.projectMenuLink);
			}
		},

		async onAccept() {
			if (this.notiMode === CommonConst.EDIT_MODE) {
				this.editProject();
				return;
			}
			if (this.notiMode === CommonConst.EDIT_HOLDINGTAX_MODE) {
				let [response, errorUpdate] = await ProjectService.update(this.projectId, {
					with_holding_tax: this.model.withHoldingTax.length,
				});
				socketClient.send('data_transfer', {
					room: this.projectId,
					data: {
						event_name: 'admin_update_project',
					},
				});
			} else {
				this.deleteProject();
			}
		},
		onDeclineChangeHoldingTax() {
			if (this.notiMode != CommonConst.EDIT_HOLDINGTAX_MODE) return;
			this.model.withHoldingTax = this.model.withHoldingTax.length ? [] : [1];
		},

		onCheckHoldingTax() {
			this.notiMode = CommonConst.EDIT_HOLDINGTAX_MODE;
			this.redirectLink = this.projectMenuLink;
			this.notiContent =
				'この設定を変更すると、\n作成済みのお見積り書などには変更が反映されません。\nまた、お見積り書作成以降に設定変更すると、\nお見積もり書と請求書の金額が相違する可能性があります。\n本当に変更してよろしいですか？';
			this.$bvModal.show(`${this.notiModalId}`);
		},

		async getLineVerifyUrl() {
			let [response, _] = await ProjectService.get(this.projectId);
			let [line, error] = await ClientService.getLineVerifyUrl(response.client_id);
			if (line.line_verify_url) {
				this.lineVerifyUrl = line.line_verify_url;
			} else {
				let [response, error] = await ClientService.createLineVerifyUrl(
					this.model.clientInfo.value
				);
				if (response) {
					this.lineVerifyUrl = response.line_verify_url;
				}
			}
		},
		onCopy(copyData, inputIdx) {
			let copyDataFormatted = '';
			if (inputIdx === this.urlNumber.lineVerifyUrl) {
				copyDataFormatted = `${CommonConst.PRE_TEXT_LINE_LINK}${copyData}`;
			} else {
				copyDataFormatted = copyData;
			}
			let self = this;
			this.$copyText(copyDataFormatted).then(
				function (e) {
					self.textContent.splice(inputIdx, 1, 'コピー済み');
					setTimeout(() => {
						self.textContent.splice(inputIdx, 1, 'URLをコピー');
					}, 1000);
					console.log(e);
				},
				function (e) {
					self.textContent.splice(inputIdx, 1, 'コピー失敗');
					console.log(e);
				}
			);
		},

		/**
		 * Check status is freeze or not
		 * @param {integer} projectId
		 */
		async checkFreezeProject(projectId) {
			try {
				const project = await this.getEditProjectById(projectId);
				this.projectStatus = project['status'];
				this.beforeFreezeStatus = project['before_freeze_status'];
				this.isFrezzeProject = this.projectStatus === 10 ? true : false;
			} catch (error) {
				console.log(error);
			}
		},
	},
	async mounted() {
		socketClient.listen('new_data_transfer', this.dataTransferListener);

		this.getLineVerifyUrl();
		await this.checkFreezeProject(this.projectId);
	},

	beforeDestroy() {
		socketClient.removeListener('new_data_transfer', this.dataTransferListener);
	},
};
</script>
<style scoped>
.star-rating {
	pointer-events: none;
	padding-bottom: 3px;
}
</style>
